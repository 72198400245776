.sidemenu-button {
    border: none;
    background: none;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    font-family: 'Mulish', sans-serif;
    color: #000000;
    display: inline-block;
    text-decoration: none;
    transition: all .5s ease;
    text-align: left;
}

.sidemenu-button:hover {
    padding-left: 20px;
    color: #313190;
    transition: all .5s ease;
}