@import url('./responsive.css');

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    opacity: .5 !important;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #0000005b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #878787;
}

.product-slider .slick-prev,
.product-slider .slick-next {
    z-index: 1;
    padding-top: 2px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0);
    height: 35px;
    width: 35px;
}

.product-slider .slick-prev:hover,
.product-slider .slick-next:hover {
    background-color: #00000009;
}

.product-slider .slick-prev {
    left: 20px;
}

.product-slider .slick-next {
    right: 20px;
}

.product-slider .slick-prev::before,
.product-slider .slick-next::before {
    color: white !important;
    text-shadow: 0 0 10px black;
}

.product-thumb-image {
    height: 90px !important;
    object-fit: cover;
}

.thumb-sliders .slick-slide {
    transition: all 1s;
}

.thumb-sliders .slick-slide.slick-active.slick-current .thumb-image-wrapper {
    transition: all 1s;
    border: 1px solid black !important;
}

.thumb-image-wrapper {
    width: 100px !important;
    height: 100px !important;
}

.product-slider-image {
    height: 450px !important;
    object-fit: contain;
    cursor: zoom-in;
}

.product-image-wrapper {
    width: 100% !important;
    height: 500px !important;
}

.product-image-wrapper,
.thumb-image-wrapper {
    text-align: center !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.thumb-sliders .slick-slide>div {
    padding: 0 10px !important;
}

.card-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#homeCarousel.carousel .carousel-item {
    padding-bottom: 30px;
}

.carousel-indicators {
    margin: auto !important;
    bottom: 9px;
}

.product-card-image {
    height: 150px !important;
    margin: 25px 0;
    object-fit: contain;
}

.custom-card {
    min-height: 390px;
}

.margin-price-top {
    margin-top: 45px;
}

.custom-card,
.custom-card:hover {
    transition: all .7s;
}

/* .custom-card:hover {
    transform: scale(.9);
} */

.strike-rate {
    font-size: 14px;
    color: #b60000;
}

.btn-dark,
.product-features .Mui-selected {
    transition: all .8s;
    background-image: linear-gradient(to left, #121242, #16164c, #1a1a57, #1f1f62, #23236d, #272777, #2b2b82, #2f2f8c, #343499, #3939a6, #3e3eb3, #4343c0);
}

.btn-dark:hover,
.product-features .Mui-selected:hover {
    transition: all .8s;
    background-image: linear-gradient(to right bottom, #121242, #16164c, #1a1a57, #1f1f62, #23236d, #272777, #2b2b82, #2f2f8c, #343499, #3939a6, #3e3eb3, #4343c0);
}

.product-features .Mui-selected {
    color: white !important;
    font-weight: 500;
}

.cstm-chkbx small {
    font-size: 14px !important;
    color: black !important;
}

.list-group-scrollable {
    max-height: 350px;
    overflow-y: auto;
}

.product-chip {
    background: linear-gradient(36deg, red, #34318e)
}

.custom-card:hover img {
    transform: scale(1.2);
}

.cursor {
    cursor: pointer;
}

.web-menu {
    min-height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background: linear-gradient(180deg, #2E2E85, #21143e);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.moby-banner {
    display: none !important;
}

.mobile-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.carousel-box-overlay {
    position: relative;
} 
.carousel-box-overlay .carousel-caption {
    transition: all 1s !important;
    transform: translateY(-50%) !important;
    top: 50%;
}
.carousel-box-overlay:hover .carousel-caption {
    transition: all 1s;
}
.carousel-box-overlay:hover::before {
    opacity: 1;
    cursor: pointer;
    transition: all 1s;
}
.carousel-box-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #1a1a1ad1,#000000);
    opacity: .5;
    z-index: 1;
    transition: all 1s;
}

/* .carousel-caption ,
.carousel-box-overlay::before,
.carousel-box-overlay:hover::before {
    transition: all 1s;
} */
/* .carousel-box-overlay:hover::before {
    background-color: transparent;
} */
.carousel-caption {
    text-shadow: 0 0 10px black;
    /* transform: translateY(10px); */
    z-index: 2;
}

body {
    padding-top: 144px;
}

.secondary-menu {
    display: flex;
    height: 35px;
    align-items: center;
    justify-content: center;
}

.secondary-menu-link {
    background-color: transparent !important;
    padding: 10px 15px;
    color: #bfbfbf;
    font-weight: bold;
}

.secondary-menu-link:hover,
.secondary-menu-link.active {
    color: white;
}

@media (max-width:576px) {
    .fs-max-20 {
        font-size: 20px !important;
    }

    .p-max-sm-2 {
        padding: 10px;
    }
}

#coupon_apply_btn {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.brand a {
    position: relative;
}

.brand a::after {
    content: '';
    height: 30px;
    width: 1px;
    background-color: #606060;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

@media (max-width:600px) {
    .brand a::after {
        top: unset !important;
        bottom: -3px !important;
        left: 50%;
        height: 1px !important;
        width: 40px;
        transform: translateX(-50%);
        background-color: #ffffff;
    }
}

.brand img {
    width: 230px !important;
    height: 80px !important;
    margin: auto;
    filter: brightness(0) invert(1);
    object-fit: contain;
}

@media (max-width:600px) {
    .payment-icon {
        width: 150px !important;
    }

    .title-1 {
        font-size: 25px !important;
        line-height: 30px !important;
    }

    .title-2 {
        font-size: 18px !important;
        line-height: 25px !important;
    }

    .jumbotron {
        text-align: center;
    }

    .next-jump {
        text-align: center;
    }

    .border-bottom-max-md {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }

    .our-brands .slick-next {
        right: 15px;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 10px gray;
    }

    .our-brands .slick-prev {
        left: 15px;
        z-index: 111;
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 10px gray;
    }

    .brand {
        text-align: center;
        width: 200px;
    }

    .brand a {
        width: 100%;
        text-align: center;
    }

    .brand img {
        width: 100% !important;
        margin: auto;
    }

    .pc-banner {
        display: none !important;
    }

    .moby-banner {
        display: block !important;
    }

    .web-menu {
        min-height: 50px;
    }

    body {
        padding-top: 55px;
    }

    #cssmenu ul {
        top: 0px !important;
        overflow: hidden !important;
    }

    .logo-social,
    .quick-links,
    .contact-links {
        text-align: center;
    }

    .logo-social img {
        width: 120px;
    }

    .logo-social ul li {
        margin: 0 10px !important;
    }

    .logo-social ul {
        margin-top: 20px;
        transform: translate(0px) !important
    }

    .package-support {
        padding: 20px 0 !important;
    }

    .package-support img {
        width: 150px;
        height: 80px;
        object-fit: cover;
    }

    .quick-links h4 {
        font-size: 15px;
    }

    .quick-links li a {
        font-size: 13px;
    }

    .bottom-bar p {
        font-size: 11px;
        line-height: 16px;
        text-align: center;
    }

    .common-heads h1,
    .common-heads.light h1 {
        font-size: 18px;
        line-height: 25px;
        text-align: center;
    }

    .shopping-video,
    .shopping-book {
        text-align: center;
    }

    section {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@media (min-width:1000px) {
    .padding-top {
        padding-top: 180px;
    }
}

#titlediv {
    display: none !important;
}

@media (max-width:1000px) {
    .menu-open {
        height: 100vh !important;
    }

    .srch-btn,
    header .button {
        top: -40px !important;
    }

    .top-logo img {
        width: 90px !important;
    }

    .secondary-menu {
        display: none !important;
    }
}

.zsiq_theme1.zsiq_floatmain {
    bottom: 66px !important;
}

.deal-image-wrapper {
    width: 100%;
    height: 100px;
    position: relative;
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
}

.deal-image {
    height: 60px;
}

.slick-dots {
    position: unset !important;
}

.the-trending .slick-dots {
    margin-top: 20px;
}

.product-list-image {
    height: 100px;
}



@keyframes pulse {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05)
    }

    to {
        -webkit-transform: scaleX(1);
        transform: scaleX(1)
    }
}

.CartButton {
    animation: pulse 5s infinite;
}